/*=========================
	All css
==========================*/
@import url("../plugins/bootstrap/css/bootstrap.min.css");
@import url("../plugins/themify/themify-icons.css");
@import url("../plugins/fontawesome/css/font-awesome.min.css");
/*=========================
	All css
==========================*/
@import url('https://fonts.googleapis.com/css?family=Barlow:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Lora:400,400i,700,700i|Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
