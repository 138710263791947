// Colors
$primary: #2b74a4 !default;
$primaryLight: scale-color($primary, $lightness: 60%) !default;
$primaryLightest: scale-color($primary, $lightness: 90%) !default;
$primaryDark: scale-color($primary, $lightness: -10%) !default;
$primaryDarker: scale-color($primary, $lightness: -20%) !default;
$primaryDarkest: scale-color($primary, $lightness: -60%) !default;

$textColor: #ffffff !default;
$background: #ffffff !default;
$grey: #6f6f6f !default;
$darkGrey: #2d2d2d !default;
